import { template as template_198f116438214ab4b53bcb6bc2289a44 } from "@ember/template-compiler";
const SidebarSectionMessage = template_198f116438214ab4b53bcb6bc2289a44(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
