import { template as template_dffcad7181c347edacd4446babb5dc68 } from "@ember/template-compiler";
const FKLabel = template_dffcad7181c347edacd4446babb5dc68(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
